import iMenuSideItem from './menu-item';
import iMenuSideTitle from './menu-title';
import siderMenuBadge from '../mixins/sider-menu-badge';
export default {
  name: 'iMenuSideSubmenu',
  components: {
    iMenuSideItem,
    iMenuSideTitle
  },
  mixins: [siderMenuBadge],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    showMenu(item) {
      if (item.meta) {
        // 这个是决定那个角色才有权限看当前菜单
        if (item.meta.permission) {
          let userInfo = this.$store.getters['admin/user/getInfo'];
          console.log("🚀 ~ showMenu ~ userInfo:", userInfo);

          // 设置可视的用户列表
          let userList = item.meta.permission;
          return userList.length === 0 ? true : userList.includes(userInfo.userId);
        }
      }
      return true;
    }
  }
};