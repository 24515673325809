import request from '@/plugins/request';
export function AccountLogin(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  });
}
export function AccountRegister(data) {
  return request({
    url: '/api/register',
    method: 'post',
    data
  });
}

// 获取验证码
export function getCaptcha(params) {
  return request({
    url: '/api/riverbackend/v1/captcha',
    method: 'get',
    params
  });
}

// 登录
export function accountLogin(data) {
  return request({
    url: '/api/riverbackend/v1/login',
    method: 'post',
    data
  });
}

// 登出
export function accountLogout(data) {
  return request({
    url: '/api/riverbackend/v1/logout',
    method: 'post',
    data
  });
}

// 根据Token获取用户信息
export function getUserInfoByToken(params) {
  return request({
    url: '/api/riverbackend/v1/user/token',
    method: 'get',
    params
  });
}