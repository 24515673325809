export default {
  __name: 'index',
  setup(__props, {
    expose: __expose
  }) {
    __expose();
    const openWeb = url => {
      window.open(url, '_blank');
    };
    const __returned__ = {
      openWeb
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};