const pre = "/admin/";
export default {
  path: "/admin",
  title: "菜单",
  header: "home",
  icon: "md-speedometer",
  children: [{
    path: `${pre}longRiverManagement`,
    title: "河长牌管理"
  },
  // {
  //   path: `${pre}console`,
  //   title: "河长牌管理",
  // },
  // {
  //   path: `${pre}longRiverManagement`,
  //   title: "河长牌管理",
  // },
  {
    path: `${pre}answerTheQuestions`,
    title: "题库管理"
  }, {
    path: `${pre}questionnaire`,
    title: "问卷管理"
  }, {
    path: `${pre}clientManagement`,
    title: "客户端管理",
    meta: {
      permission: [23, 24, 120, 152, 167, 195, 220, 264]
    }
  }, {
    path: `${pre}setting`,
    title: "河长牌全局配置"
  }]
};