import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  return _openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["i-layout-menu-side-title", {
      'i-layout-menu-side-title-with-collapse': $props.collapse
    }])
  }, [$options.withIcon ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: _normalizeClass(["i-layout-menu-side-title-icon", {
      'i-layout-menu-side-title-icon-single': $props.hideTitle
    }])
  }, [$props.menu.icon ? (_openBlock(), _createBlock(_component_Icon, {
    key: 0,
    type: $props.menu.icon
  }, null, 8 /* PROPS */, ["type"])) : $props.menu.custom ? (_openBlock(), _createBlock(_component_Icon, {
    key: 1,
    custom: $props.menu.custom
  }, null, 8 /* PROPS */, ["custom"])) : $props.menu.img ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    src: $props.menu.img
  }, null, 8 /* PROPS */, _hoisted_1)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$props.hideTitle ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    class: _normalizeClass(["i-layout-menu-side-title-text", {
      'i-layout-menu-side-title-text-selected': $props.selected,
      'i-layout-menu-side-title-text-with-subtitle': $props.menu.subtitle,
      'i-layout-menu-side-title-text-with-icon': $options.withIcon
    }])
  }, [_createTextVNode(_toDisplayString(_ctx.tTitle($props.menu.title)), 1 /* TEXT */), $props.menu.subtitle ? (_openBlock(), _createElementBlock("em", _hoisted_2, _toDisplayString(_ctx.tTitle($props.menu.subtitle)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}