import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "i-layout-header-right"
};
const _hoisted_2 = {
  class: "i-layout-content-main"
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side = _resolveComponent("i-menu-side");
  const _component_Sider = _resolveComponent("Sider");
  const _component_i_header_logo = _resolveComponent("i-header-logo");
  const _component_i_header_collapse = _resolveComponent("i-header-collapse");
  const _component_i_header_reload = _resolveComponent("i-header-reload");
  const _component_i_menu_head = _resolveComponent("i-menu-head");
  const _component_i_header_breadcrumb = _resolveComponent("i-header-breadcrumb");
  const _component_i_header_search = _resolveComponent("i-header-search");
  const _component_i_header_log = _resolveComponent("i-header-log");
  const _component_i_header_fullscreen = _resolveComponent("i-header-fullscreen");
  const _component_i_header_notice = _resolveComponent("i-header-notice");
  const _component_i_header_helpcenter = _resolveComponent("i-header-helpcenter");
  const _component_i_header_user = _resolveComponent("i-header-user");
  const _component_i_header_i18n = _resolveComponent("i-header-i18n");
  const _component_i_header_setting = _resolveComponent("i-header-setting");
  const _component_Header = _resolveComponent("Header");
  const _component_i_tabs = _resolveComponent("i-tabs");
  const _component_router_view = _resolveComponent("router-view");
  const _component_Content = _resolveComponent("Content");
  const _component_i_copyright = _resolveComponent("i-copyright");
  const _component_Layout = _resolveComponent("Layout");
  const _component_Drawer = _resolveComponent("Drawer");
  const _component_i_water_mark = _resolveComponent("i-water-mark");
  const _directive_resize = _resolveDirective("resize");
  return _openBlock(), _createBlock(_component_Layout, {
    class: "i-layout"
  }, {
    default: _withCtx(() => [!_ctx.isMobile && !_ctx.hideSider ? (_openBlock(), _createBlock(_component_Sider, {
      key: 0,
      class: _normalizeClass(["i-layout-sider", $options.siderClasses]),
      width: $options.menuSideWidth
    }, {
      default: _withCtx(() => [_createVNode(_component_i_menu_side, {
        "hide-logo": $options.isHeaderStick && _ctx.headerFix && $options.showHeader
      }, null, 8 /* PROPS */, ["hide-logo"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "width"])) : _createCommentVNode("v-if", true), _createVNode(_component_Layout, {
      class: _normalizeClass(["i-layout-inside", $options.insideClasses])
    }, {
      default: _withCtx(() => [_createVNode(_Transition, {
        name: "fade-quick",
        persisted: ""
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_Header, {
          class: _normalizeClass(["i-layout-header", $options.headerClasses]),
          style: _normalizeStyle($options.headerStyle)
        }, {
          default: _withCtx(() => [_ctx.isMobile && _ctx.showMobileLogo ? (_openBlock(), _createBlock(_component_i_header_logo, {
            key: 0
          })) : _createCommentVNode("v-if", true), !_ctx.isMobile && $options.isHeaderStick && _ctx.headerFix ? (_openBlock(), _createBlock(_component_i_header_logo, {
            key: 1
          })) : _createCommentVNode("v-if", true), (_ctx.isMobile || _ctx.showSiderCollapse) && !_ctx.hideSider ? (_openBlock(), _createBlock(_component_i_header_collapse, {
            key: 2,
            onOnToggleDrawer: $options.handleToggleDrawer
          }, null, 8 /* PROPS */, ["onOnToggleDrawer"])) : _createCommentVNode("v-if", true), !_ctx.isMobile && _ctx.showReload ? (_openBlock(), _createBlock(_component_i_header_reload, {
            key: 3,
            onOnReload: $options.handleReload
          }, null, 8 /* PROPS */, ["onOnReload"])) : _createCommentVNode("v-if", true), _ctx.headerMenu && !_ctx.isMobile ? (_openBlock(), _createBlock(_component_i_menu_head, {
            key: 4,
            ref: "menuHead"
          }, null, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _ctx.showBreadcrumb && !_ctx.headerMenu && !_ctx.isMobile ? (_openBlock(), _createBlock(_component_i_header_breadcrumb, {
            key: 5,
            ref: "breadcrumb"
          }, null, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true), _ctx.showSearch && !_ctx.headerMenu && !_ctx.isMobile && !_ctx.showBreadcrumb ? (_openBlock(), _createBlock(_component_i_header_search, {
            key: 6
          })) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_ctx.showSearch && _ctx.isMobile || _ctx.showSearch && (_ctx.headerMenu || _ctx.showBreadcrumb) ? (_openBlock(), _createBlock(_component_i_header_search, {
            key: 0
          })) : _createCommentVNode("v-if", true), _ctx.headerMenu && _ctx.isMobile ? (_openBlock(), _createBlock(_component_i_menu_head, {
            key: 1
          })) : _createCommentVNode("v-if", true), _ctx.isDesktop && _ctx.showLog ? (_openBlock(), _createBlock(_component_i_header_log, {
            key: 2
          })) : _createCommentVNode("v-if", true), _ctx.isDesktop && _ctx.showFullscreen ? (_openBlock(), _createBlock(_component_i_header_fullscreen, {
            key: 3
          })) : _createCommentVNode("v-if", true), _ctx.showNotice ? (_openBlock(), _createBlock(_component_i_header_notice, {
            key: 4
          })) : _createCommentVNode("v-if", true), _createVNode(_component_i_header_helpcenter), _createVNode(_component_i_header_user), _ctx.showI18n ? (_openBlock(), _createBlock(_component_i_header_i18n, {
            key: 5
          })) : _createCommentVNode("v-if", true), _ctx.enableSetting && !_ctx.isMobile ? (_openBlock(), _createBlock(_component_i_header_setting, {
            key: 6
          })) : _createCommentVNode("v-if", true)])]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "style"])), [[_vShow, $options.showHeader], [_directive_resize, $options.handleHeaderWidthChange]])]),
        _: 1 /* STABLE */
      }), _createVNode(_component_Content, {
        class: _normalizeClass(["i-layout-content", $options.contentClasses])
      }, {
        default: _withCtx(() => [_createVNode(_Transition, {
          name: "fade-quick"
        }, {
          default: _withCtx(() => [_ctx.tabs ? _withDirectives((_openBlock(), _createBlock(_component_i_tabs, {
            key: 0,
            onOnReload: $options.handleReload
          }, null, 8 /* PROPS */, ["onOnReload"])), [[_vShow, $options.showHeader]]) : _createCommentVNode("v-if", true)]),
          _: 1 /* STABLE */
        }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component
          }) => [(_openBlock(), _createBlock(_KeepAlive, {
            include: _ctx.keepAlive
          }, [$data.loadRouter ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: Component.name
          })) : _createCommentVNode("v-if", true)], 1032 /* PROPS, DYNAMIC_SLOTS */, ["include"]))]),
          _: 1 /* STABLE */
        })])]),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]), _createVNode(_component_i_copyright)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]), _ctx.isMobile && !_ctx.hideSider ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_Drawer, {
      modelValue: $data.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.showDrawer = $event),
      placement: "left",
      closable: false,
      "class-name": $options.drawerClasses
    }, {
      default: _withCtx(() => [_createVNode(_component_i_menu_side)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class-name"])])) : _createCommentVNode("v-if", true), _createVNode(_component_i_water_mark)]),
    _: 1 /* STABLE */
  });
}