import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_side_title = _resolveComponent("i-menu-side-title");
  const _component_Badge = _resolveComponent("Badge");
  const _component_i_menu_side_item = _resolveComponent("i-menu-side-item");
  const _component_i_menu_side_submenu = _resolveComponent("i-menu-side-submenu");
  const _component_Submenu = _resolveComponent("Submenu", true);
  return _openBlock(), _createBlock(_component_Submenu, {
    name: $props.menu.path
  }, {
    title: _withCtx(() => [_createVNode(_component_i_menu_side_title, {
      menu: $props.menu
    }, null, 8 /* PROPS */, ["menu"]), _ctx.badgeData ? (_openBlock(), _createBlock(_component_Badge, _mergeProps({
      key: 0,
      class: "i-layout-menu-side-badge"
    }, _ctx.badgeData), null, 16 /* FULL_PROPS */)) : _createCommentVNode("v-if", true)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu.children, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [(item.children === undefined || !item.children.length) && $options.showMenu(item) ? (_openBlock(), _createBlock(_component_i_menu_side_item, {
        menu: item,
        key: index
      }, null, 8 /* PROPS */, ["menu"])) : $options.showMenu(item) ? (_openBlock(), _createBlock(_component_i_menu_side_submenu, {
        key: 1,
        menu: item
      }, null, 8 /* PROPS */, ["menu"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
    }), 128 /* KEYED_FRAGMENT */))]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["name"]);
}