import BasicLayout from "@/layouts/basic-layout";
const meta = {
  auth: true
};
const pre = "admin-"; // 要一致

export default {
  path: "/admin",
  // 要一致
  name: "longRiverManagement",
  // redirect: {
  //   name: `${pre}console`,
  // },
  meta,
  component: BasicLayout,
  children: [{
    path: "longRiverManagement",
    name: `${pre}longRiverManagement`,
    meta: {
      ...meta,
      title: "河长牌管理",
      closable: true
    },
    component: () => import("@/pages/longRiverManagement/index.vue")
  },
  // {
  //   path: "console",
  //   name: "${pre}console",
  //   meta: {
  //     title: "河长牌管理",
  //     auth: true,
  //   },
  //   component: () => import("@/pages/longRiverManagement/index.vue"),
  // },
  {
    path: "longRiverManagement",
    name: "longRiverManagement",
    meta: {
      title: "河长牌管理",
      auth: true
    },
    component: () => import("@/pages/longRiverManagement/index.vue")
  }, {
    path: "answerTheQuestions",
    name: "answerTheQuestions",
    meta: {
      title: "题库管理",
      auth: true
    },
    component: () => import("@/pages/answerTheQuestions/index.vue")
  }, {
    path: "questionnaire",
    name: "questionnaire",
    meta: {
      title: "问卷管理",
      auth: true
    },
    component: () => import("@/pages/questionnaire/index.vue")
  }, {
    path: "clientManagement",
    name: "clientManagement",
    meta: {
      title: "客户端管理",
      auth: true
    },
    component: () => import("@/pages/clientManagement/index.vue")
  }, {
    path: "setting",
    name: "setting",
    meta: {
      title: "河长牌全局配置",
      auth: true
    },
    component: () => import("@/pages/setting/index.vue")
  }]
};