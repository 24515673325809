import tTitle from '../mixins/translate-title';
export default {
  name: 'iMenuSideTitle',
  mixins: [tTitle],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    // 用于侧边栏收起 Dropdown 当前高亮
    selected: {
      type: Boolean,
      default: false
    },
    // 侧边栏折叠状态
    collapse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    withIcon() {
      return this.menu.icon || this.menu.custom || this.menu.img;
    }
  }
};